<template>
  <div id="homeProductTraceComponent" style="padding-top: 16px;">
    <div style="display: flex; width: 100%;" v-for="(item, index) in dataList" :key="index">
      <div style="margin-right: 5px;text-align: -webkit-center;">
        <div style="margin-bottom: 4px;"/>
        <div class="homeProductTraceIcon"/>
        <div style="height: calc(100% - 5px - 6px); width: 0; border: #c9e5c8 1px dashed; margin-top: 4px;"
             :style="{ border: index === (dataList.length - 1) ? `#f5f6f8 1px dashed` : `#c9e5c8 1px dashed` }"/>
      </div>
      <!-- 货源产地 -->
        <div style="flex: 1;" v-if="item.type === 'ADDRESS'">
        <div class="box">
          <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
            <div class="title" style="flex: 1;">
              <span>{{ '货源产地' }}</span>
            </div>

          </div>

          <div style="display: flex; margin-top: 4px;">
            <div style="flex: 1;">
              <div style="display: flex; color: #A1A1A1;margin: 8px;">
                <div style="display: flex">
                  供
                  <div style="width: 7px;"/>
                  货
                  <div style="width: 7px;"/>
                  商:
                </div>
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">{{ item.upstreamSupplierName }}</div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin: 8px;" v-if="item.purchaseTime">
                进货日期:
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">
                  {{ item.purchaseTime | moment('yyyy-MM-DD HH:mm:ss') }}
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin: 8px;" v-if="item.quarantineCertificateUrl">
                检验检疫:
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">
                <img @click="imgPlus(item.quarantineCertificateUrl)" style="width: 100px;height: 100px;" :src="item.quarantineCertificateUrl" alt="">
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin: 8px;" v-if="item.qualityQualifiedUrl">
                质检报告:
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">
                <img @click="imgPlus(item.qualityQualifiedUrl)" style="width: 100px;height: 100px;" :src="item.qualityQualifiedUrl" alt="">
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin: 8px;" v-if="item.legalInspectionUrl">
                法检报告:
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">
                <img @click="imgPlus(item.legalInspectionUrl)" style="width: 100px;height: 100px;" :src="item.legalInspectionUrl" alt="">
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <!-- 供货来源 -->
      <div style="flex: 1;" v-if="item.type === 'SUPPLIER'">
        <div class="box">
          <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
            <div class="title" style="flex: 1;">
              <span>{{ '供货来源' }}</span>
            </div>

          </div>

          <div style="display: flex; margin-top: 4px;">
            <div style="flex: 1;">
              <div style="display: flex; color: #A1A1A1;margin: 8px;">
                <div style="display: flex">
                  供
                  <div style="width: 7px;"/>
                  货
                  <div style="width: 7px;"/>
                  商:
                </div>
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">{{ item.supplierName }}</div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin: 8px;" v-if="item.sendTime">
                进货日期:
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">
                  {{ item.sendTime | moment('yyyy-MM-DD HH:mm:ss') }}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 区块链存证 -->
      <div style="flex: 1;" v-if="item.type === 'DIGITAL_VOUCHER'">
        <div class="box">
          <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
            <div class="title" style="flex: 1;">
              <span>{{ '区块链存证' }}</span>
            </div>

          </div>

          <div style="display: flex; margin-top: 4px;">
            <div style="flex: 1;">
              <div style="display: flex; color: #A1A1A1;margin: 8px;">
                <span style="letter-spacing: 3px;">凭证ID:</span>
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px; word-break: break-word;">
                  {{ item.digitalVoucherContractId }}
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1;margin: 8px;">
                存证时间:
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">
                  {{ item.digitalVoucherReportTime | moment('yyyy-MM-DD HH:mm:ss') }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- 检疫票证 -->
      <div style="flex: 1;"
           v-if="item.type === 'LICENSE'">
        <div class="box">
          <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
            <div class="title" style="flex: 1;">
              <span>{{ '检疫票证' }}</span>
            </div>

          </div>

          <div style="margin-top: 4px;">
            <div style="font-size: 14px; color: #222222;">

              <div style="margin: 8px;" v-if="item.certificateOfMeatQualityInspectionFileUrl">
                <div style="margin-bottom: 8px;">
                  {{ '肉品品质检验合格证' }}
                </div>

                <img
                    v-lazy="(item.certificateOfMeatQualityInspectionFileUrl ? item.certificateOfMeatQualityInspectionFileUrl : '')"
                    style="width: 100%; height: calc((100vw - 88px)); object-fit: fill;"/>

              </div>

              <div style="margin: 8px;" v-if="item.animalQuarantineCertificateFileUrl">
                <div style="margin-bottom: 8px;">
                  {{ '动物检疫合格证明' }}
                </div>

                <img v-lazy="(item.animalQuarantineCertificateFileUrl ? item.animalQuarantineCertificateFileUrl : '')"
                     style="width: 100%; height: calc((100vw - 88px)); object-fit: fill;"/>
              </div>

              <div style="margin: 8px;" v-if="item.qualityQualifiedUrls.length > 0">
                <div style="margin-bottom: 8px;">
                  {{ '质检凭证' }}
                </div>

                <div v-for="(image, index) in item.qualityQualifiedUrls" :key="index">
                  <img v-lazy="image" style="width: 100%; height: calc((100vw - 88px)); object-fit: fill;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品采购 -->
      <div style="flex: 1; "
           v-if="item.type === 'PURCHASER'">
        <div class="box">
          <div style="display: flex; font-size: 14px; color: #222222; font-weight: bold;">
            <div class="title" style="flex: 1;">
              <span>{{ '产品采购' }}</span>
            </div>

          </div>

          <div style="display: flex; margin-top: 4px;">
            <div style="flex: 1;">
              <div style="display: flex; color: #A1A1A1;margin: 8px;">
                <span style="letter-spacing: 3px;">采购商:</span>
                <div style="flex: 1; color: #222222; margin: auto 0px auto 8px;">{{ item.purchaserName }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
      

    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { ImagePreview  } from "vant";
Vue.use(ImagePreview);
export default {
  name: "HomeProductTraceComponent",
  props: [
    'dataList'
  ],
  data() {
    return {
      qualityQualifiedUrls: []
    }
  },
  methods: {
    imgPlus(url){
      ImagePreview({
        images: [url],
        closeable: true,
      });
    },
    onClickFirst() {
      this.$parent.onClickShowBusiness(this.first.trustList);
    },
    onClickFeedingRecord(item) {
      this.$parent.onClickShowFeedingRecord(item.id);
    },
    onClickVaccinumRecord(item) {
      this.$parent.onClickShowVaccinumRecord(item.id);
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  flex: 1;
  background: #fff;
  border-radius: 6px;
  border-top: 2px solid #C2E6C5;
  margin: 0 0 16px;

  .title {

    span {
      display: inline-block;
      padding: 6px 20px 6px 8px;
      border-radius: 7px 0;
      position: relative;
      z-index: 1;
      overflow: hidden;
      color: #34AC40;

      &::after {
        content: "";
        background: #c9e5c8;
        display: inline-block;
        position: absolute;
        top: 0px;
        left: -3px;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: skewX(-10deg);
        overflow: hidden;
        border-radius: 0 0 7px 0;
      }
    }

  }

  .box-detail {
    padding: 6px 8px;
    font-size: 12px;
    color: #999999;
    display: flex;
    align-items: center;
    font-weight: initial;
  }

  .box-info {
    padding: 0 8px;
  }

  ::v-deep.van-image__img {
    border-radius: 5px;
  }
}

.homeProductTraceIcon {
  height: 15px;
  width: 15px;
  background: url(../assets/images/double-dot.png) no-repeat center / cover;
  margin-top: 10px;
}
</style>